import React from 'react';
import { useLocation } from 'react-router-dom';

const PrintGatepass = () => {
  const location = useLocation();

  const gatepassId = location.state.gatepassd;
  return (
    <div>
      whatenver
      <h1>hola blackkalu...</h1>
    </div>
  );
};

export default PrintGatepass;
