import * as Yup from 'yup';
import React, { useState } from 'react';
// material-ui
import { Typography, Stack, TextField } from '@mui/material';
// Formik
import { FormikProvider, Form, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Repeater from '../repeater';
import client, { baseURL } from '../../api/client';

export const ItemForm = ({ gatepassData, setGatepassData }) => {
  const ItemSchema = Yup.object().shape({
    name: Yup.string().required('Particular is required'),
    qty: Yup.number().required('Quantity is required')
  });

  const [count, setCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: '',
      qty: ''
    },
    validationSchema: ItemSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      setCount(count + 1);
      // ** axios post to create user
      client
        .post(`${baseURL}/items`, {
          name: values.name,
          qty: values.qty
        })
        .then(({ data }) => {
          const allItems = gatepassData.items;
          allItems.push(data.id);
          setGatepassData({ ...gatepassData, items: allItems });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [submitting, setSub] = useState([false]);

  // TODO: Might not work need some changes to dooo...
  const handleSubmitR = (e, index) => {
    e.preventDefault();
    const sub = submitting;
    sub[index] = true;
    setCount(count + 1);
    setSub(sub);
    // ** axios post to create user
    client
      .post(`${baseURL}/items`, {
        name: values.name,
        qty: values.qty
      })
      .then(({ data }) => {
        const allItems = gatepassData.items;
        allItems.push(data.id);
        setGatepassData({ ...gatepassData, items: allItems });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Add Items
      </Typography>
      <FormikProvider value={formik} sx={{ my: '1rem' }}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} direction="row">
            <TextField
              fullWidth
              type="text"
              label="Particular"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="number"
              label="Qty"
              {...getFieldProps('qty')}
              error={Boolean(touched.qty && errors.qty)}
              helperText={touched.qty && errors.qty}
            />
            <LoadingButton
              sx={{ my: 2.5 }}
              width="20%"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Repeater count={count}>
        {(index) => (
          <div key={index} style={{ margin: '1rem 0' }}>
            <form onSubmit={(e) => handleSubmitR(e, index)}>
              <Stack spacing={3} direction="row">
                <TextField fullWidth type="text" label="Particular" />
                <TextField fullWidth type="number" label="Qty" />
                <LoadingButton
                  sx={{ my: 2.5 }}
                  width="20%"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={submitting[index]}
                >
                  Add
                </LoadingButton>
              </Stack>
            </form>
          </div>
        )}
      </Repeater>
    </>
  );
};
