import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Modal,
  Card,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button,
  CircularProgress,
  Box
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import crossOutline from '@iconify/icons-eva/close-outline';
import client, { baseURL } from '../api/client';
// components
import Page from '../components/Page';
import { ItemForm } from '../components/gatepass/ItemForm';

// ----------------------------------------------------------------------

export default function AddGatepass() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [gatepassData, setGatepassData] = useState({
    driver_id: '',
    grower_id: '',
    items: []
  });
  const [gatepassId, setGatepassId] = useState('');

  useEffect(() => {
    setIsLoading(true);
    client
      .get(`${baseURL}/gatepass/${location.state.gatepassId}`)
      .then(({ data }) => {
        setGatepassId(data.id);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const GrowerSchema = Yup.object().shape({
    grower_name: Yup.string().required('Grower name is required'),
    grower_phone: Yup.string().required('Grower phone is required'),
    grower_address: Yup.string().required('Grower address is required')
  });

  const DriverSchema = Yup.object().shape({
    driver_name: Yup.string().required('Driver name is required'),
    driver_phone: Yup.string().required('Driver phone is required'),
    driver_address: Yup.string().required('Driver address is required')
  });

  const growerformik = useFormik({
    initialValues: {
      grower_name: '',
      grower_phone: '',
      grower_address: ''
    },
    validationSchema: GrowerSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      // ** axios post to create user
      client
        .post(`${baseURL}/growers`, {
          name: values.grower_name,
          phone: values.grower_phone,
          residence: values.grower_address
        })
        .then(({ data }) => {
          console.log(data);
          setGatepassData({ ...gatepassData, grower_id: data.id });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  //   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = growerformik;

  const driverformik = useFormik({
    initialValues: {
      driver_name: '',
      driver_phone: '',
      driver_address: ''
    },
    validationSchema: DriverSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      // ** axios post to create user
      client
        .post(`${baseURL}/drivers`, {
          name: values.driver_name,
          phone: values.driver_phone,
          residence: values.driver_address
        })
        .then(({ data }) => {
          setGatepassData({ ...gatepassData, driver_id: data.id });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = driverformik;

  const addGatepass = () => {
    client
      .post(`${baseURL}/gatepass`, gatepassData)
      .then((res) => {
        navigate('/dashboard/gatepass', { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page title="Gatepass: Add | Black Kalu Softwar's">
      <Container>
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h3" gutterBottom>
                Add Gatepass Details
              </Typography>
              <Typography variant="h6" gutterBottom>
                Serial No: <strong>#{gatepassData.sr_no}</strong>
              </Typography>
            </Stack>
            <Card sx={{ padding: '2rem' }}>
              <Typography variant="h4" gutterBottom>
                Grower Details
              </Typography>
              <FormikProvider value={growerformik}>
                <Form autoComplete="off" noValidate onSubmit={growerformik.handleSubmit}>
                  <Stack spacing={3}>
                    <Stack spacing={3} direction="row">
                      <TextField
                        fullWidth
                        type="text"
                        label="Grower Name"
                        {...growerformik.getFieldProps('grower_name')}
                        error={Boolean(
                          growerformik.touched.grower_name && growerformik.errors.grower_name
                        )}
                        helperText={
                          growerformik.touched.grower_name && growerformik.errors.grower_name
                        }
                      />
                      <TextField
                        fullWidth
                        type="text"
                        label="Grower Phone"
                        {...growerformik.getFieldProps('grower_phone')}
                        error={Boolean(
                          growerformik.touched.grower_phone && growerformik.errors.grower_phone
                        )}
                        helperText={
                          growerformik.touched.grower_phone && growerformik.errors.grower_phone
                        }
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      type="text"
                      label="Grower Address"
                      {...growerformik.getFieldProps('grower_address')}
                      error={Boolean(
                        growerformik.touched.grower_address && growerformik.errors.grower_address
                      )}
                      helperText={
                        growerformik.touched.grower_address && growerformik.errors.grower_address
                      }
                    />
                  </Stack>

                  <LoadingButton
                    sx={{ my: 2.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={growerformik.isSubmitting}
                  >
                    Add Grower
                  </LoadingButton>
                </Form>
              </FormikProvider>
              <Typography variant="h4" gutterBottom>
                Driver Details
              </Typography>
              <FormikProvider value={driverformik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack spacing={3} direction="row">
                      <TextField
                        fullWidth
                        type="text"
                        label="Driver Name"
                        {...getFieldProps('driver_name')}
                        error={Boolean(touched.driver_name && errors.driver_name)}
                        helperText={touched.driver_name && errors.driver_name}
                      />
                      <TextField
                        fullWidth
                        type="text"
                        label="Driver Phone"
                        {...getFieldProps('driver_phone')}
                        error={Boolean(touched.driver_phone && errors.driver_phone)}
                        helperText={touched.driver_phone && errors.driver_phone}
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      type="text"
                      label="Driver Address"
                      {...getFieldProps('driver_address')}
                      error={Boolean(touched.driver_address && errors.driver_address)}
                      helperText={touched.driver_address && errors.driver_address}
                    />
                  </Stack>

                  <LoadingButton
                    sx={{ my: 2.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Add Driver
                  </LoadingButton>
                </Form>
              </FormikProvider>
              <ItemForm gatepassData={gatepassData} setGatepassData={setGatepassData} />
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginTop: '2rem' }}
                onClick={addGatepass}
              >
                Save
              </Button>
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
}
