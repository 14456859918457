import axios from 'axios';

// let authTokens = JSON.parse(localStorage.getItem('authTokens'));

const baseURL = 'https://blackalu.herokuapp.com/api/v1';
export { baseURL };

const client = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

// axios interceptor
// client.interceptors.request.use(
//   async (config) => {
//     console.log('here', config);
//     if (!authTokens) {
//       authTokens = localStorage.getItem('authTokens')
//         ? JSON.parse(localStorage.getItem('authTokens'))
//         : null;
//       config.headers.Authorization = `Bearer ${authTokens?.access?.token}`;
//     }

//     const expiryData = authTokens?.access?.expires;
//     console.log('expirey: ', expiryData);
//     const isExpired = Date.parse(expiryData) < Date.now();

//     console.log('is expired: ', isExpired);

//     if (!isExpired) return config;

//     const response = await axios.post(`${baseURL}/auth/refresh-tokens`, {
//       refreshToken: authTokens?.refresh?.token
//     });

//     console.log('REs: ', response);

//     localStorage.setItem('authTokens', JSON.stringify(response));
//     config.headers.Authorization = `Bearer ${response?.access?.token}`;
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

export default client;
