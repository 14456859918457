import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Modal,
  Card,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import crossOutline from '@iconify/icons-eva/close-outline';
import client, { baseURL } from '../../api/client';
import { MotionContainer } from '../animate';

export function AddUser({ open, handleClose }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      username: '',
      role: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      // ** axios post to create user
      client
        .post(`${baseURL}/users`, {
          email: values.email,
          password: values.password,
          username: values.username,
          role: values.role
        })
        .then(() => {
          handleClose();
          navigate('/dashboard/users', { replace: true });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [handleClose, open]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <MotionContainer display="flex" alignItems="center" justifyContent="center" height="100%">
        <Card sx={{ width: '30%', py: '1rem' }}>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h3" gutterBottom>
                Add User
              </Typography>
              <Button variant="contained" onClick={handleClose}>
                <Icon icon={crossOutline} fontSize={24} />
              </Button>
            </Stack>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="username"
                    {...getFieldProps('username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />
                  <FormControl fullWidth error={Boolean(touched.role && errors.role)}>
                    <InputLabel id="role">Role</InputLabel>
                    <Select labelId="role" id="role" label="Role" {...getFieldProps('role')}>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="gatepass">Gatepass</MenuItem>
                      <MenuItem value="moderator">Moderator</MenuItem>
                    </Select>
                    <FormHelperText>{touched.role && errors.role}</FormHelperText>
                  </FormControl>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>

                <LoadingButton
                  sx={{ my: 2.5 }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Create user
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </MotionContainer>
    </Modal>
  );
}
